<template>
  <div class="home">
    <ContentWelcome v-if="!loginOk && !showUserSettings"/>
    <FormLogin v-if="!loginOk && !showUserSettings" @onSubmit="handleLoginFormSubmit" :settings="settings" />
    <ViewSelection v-if="loginOk && !showUserSettings" @onViewChange="handleViewChange" />
    <FoldersFiles v-if="loginOk && view=='files' && !showUserSettings" />
    <Dates v-if="loginOk && view=='dates' && !showUserSettings" />
    <ChangePassword v-if="loginOk && showUserSettings" :show="showUserSettings" :settings="settings" :userId="userId" @onClose="showUserSettings = !showUserSettings" />
    <div class="privacy-notes">
      <h3>Hinweis zur Verwendung dieser App</h3>
      <p>Ich nehme zur Kenntnis, dass ich in Ausübung meiner politischen Tätigkeit Kenntnis über personenbezogene Daten, Betriebs- und Geschäftsgeheimnisse sowie vertrauliche Informationen bzw. Dokumente erhalte. All dies ist streng vertraulich zu behandeln und ich trage dafür Sorge, dass Unbefugte keine Kenntnis davon erlangen können. Allenfalls anvertraute Benutzerkennwörter, Passwörter und sonstige Zugangsberechtigungen sind sorgfältig zu verwahren und geheim zu halten. Darüber hinaus habe ich angemessene Schutzmaßnahmen getroffen, um den Schutz mir gegebenenfalls übermittelter Inhalte und (personenbezogenen) Daten zu gewährleisten Es gelten die Bestimmungen des geltenden (europäischen und österreichischen) Datenschutzrechtes sowie der einschlägigen verwaltungs- bzw. verfassungsrechtlichen Bestimmungen.</p>
    </div>
  </div>
</template>

<script>

import { ContentWelcome, FormLogin, FoldersFiles, ViewSelection, Dates, ChangePassword } from '@/components/';
import { SettingsController, LoginController } from '@/controller';

export default {
  name: "Home",
  components: {
    ContentWelcome,
    ViewSelection,
    FormLogin,
    FoldersFiles,
    Dates,
    ChangePassword
  },
  data() {
    return {
      settings: [],
      loginOk: false,
      view: "files",
      userId: 0,
      showUserSettings: false
    }
  },
  created() {
    this.getSettings();
    this.checkLogin();
  },
  mounted() {
    this.emitter.on("onUserSettings", () => {
      this.showUserSettings = true;
    });
    this.emitter.on("onLogout", () => {
      this.loginOk = false;
      this.userId = 0;
    });
  },
  methods: {
    async getSettings() {
       await SettingsController.getSettings();
       this.settings = SettingsController.settings;
    },
    async checkLogin() {
      const loggedIn = this.loginOk;
      await LoginController.checkLogin();
      this.loginOk = LoginController.loginOk;
      this.userId = LoginController.userId;
      if (!loggedIn && this.loginOk) {
        this.emitter.emit("onLogin");
      }
    },
    handleLoginFormSubmit() {
      this.loginOk = LoginController.loginOk;
      this.userId = LoginController.userId;
      if (this.loginOk) {
        this.emitter.emit("onLogin");
      }
    },
    handleViewChange(view) {
      this.view = view;
    },
  },
};
</script>
